import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import htmlDecode from "html-entities-decoder";

import Introduction from "../components/Global/Introduction"
import ServicesSection from "../components/Services/ServicesSection"


function ServicesTemplate(props) {
    console.log(props)
    let data = props.data.wpgraphql;

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    console.log(data)
    return (
        <Layout 
            header={data.menu} 
            footer={data.footer} 
            metaData={constructMetaData(data.page, props.pageContext.currentPage)}  
            services={data.services} 
            productCategories={data.productCategories}  
        >
            <div>
                <section>
                    <Introduction backImg={data.page.servicesPageAcf.introductionBackgroundImage} title={htmlDecode(data.page.servicesPageAcf.introductionHeader)} />
                </section>
                <section className="g-editor">
                    <p className="px-4 text-center m-auto mt-8 relative" style={{maxWidth:"500px", top:"25px", fontWeight:"bold"}}>Η εταιρία μας από το 2000 παρέχει υπηρεσίες κλειδαρά στο κοινό της Θεσσαλονίκης. Στόχος μας είναι η καλύτερη δυνατή εξυπηρέτηση σας, γι’ αυτό και οι τεχνικοί μας καταρτίζονται συνεχώς στις σύγχρονες τεχνικές.</p>
                </section>
                <section>
                    <ServicesSection data={data.page.servicesPageAcf} services={data.services} />
                </section>
                
            </div>
        </Layout>
    )
}

export default ServicesTemplate;

export const pageQuery = graphql`query GET_SERVICE_PAGE($id: ID!) {
  wpgraphql {
    menu: template(id:"cG9zdDozMTIz", idType:ID){
        menuAcf{
          menu{
            menuGroup{
              url
              label
              isnested
              childnodes{
                url
                label
              }
            }
          }
        }  
    }

    page(id: $id) {
      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      breadcrumbAcf {
        breadcrumbName
      }
      servicesPageAcf {
        introductionHeader
        introductionBackgroundImage {
          sourceUrl
          altText
          imageFile {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                }
            }
        }
        servicesTitleEditor
      }
    }
    services(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
      ...ServicesSectionFragment
    }

    productCategories(where: {orderby: TERM_ORDER}) {
        edges{
            node{
                name
                slug
                categoryPageH1{
                    categoryImage{
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                              gatsbyImageData(quality: 100, layout: CONSTRAINED, width:400, placeholder: BLURRED)
                            }
                        }
                    }
                }
            }
        }
    }
   
    footer: template(id: "cG9zdDoyMjE5") {
      id
      title
      footerInformationTemplate {
        footerContent
      address {
        addressGoogleLink
        addressLabel
      }
      hoursLabel
      hoursTitle
      informationTitle
      supportEmail
      telephoneNumber
      servicesTitle
      generalPagesTitle
    }
    }
  }
}
`
