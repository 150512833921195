import React from 'react'
import { Link, graphql } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image";
import htmlDecode from "html-entities-decoder";
import arrowLeft from "../../images/long-arrow-alt-right-solid.svg"

import "./ServicesSection.css";

function ServicesSection(props) {
    const data = props.data;
    const services = props.services;
    console.log(props)

    return (
        <div className="flex flex-wrap relative container m-auto my-8">
            {/* <div className="hm-ab-editor px-8 md:px-0" dangerouslySetInnerHTML={{ __html: data.servicesTitleEditor }}></div> */}
            <div className="w-full flex flex-wrap my-16">
                {services.edges.map((service, i) => (
                    <div key={`sservice-${i}`} className="w-full md:w-1/2 lg:w-1/3">
                        <Link to={`/ypiresies/${service.node.slug}/`}>
                            <div className="ss-tab-container mx-auto my-4 md:mx-4">
                                <div>
                                    <GatsbyImage
                                        image={service.node.servicesAcf.featuredImage.imageFile.childImageSharp.gatsbyImageData}
                                        style={{ maxHeight: "250px" }} imgStyle={{ borderRadius: "4px" }} />
                                </div>
                                <div className="mx-8 pb-8 block over-tab">
                                    <h3 className="w-full">{htmlDecode(service.node.title)}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: service.node.excerpt }} />

                                    <div className="pt-8">
                                        <button className="h-appointment-btn-trans flex justify-between items-center m-auto">
                                            Περισσότερα
                                            <img src={arrowLeft} width={14} className="ml-2" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ServicesSection;

export const query = graphql`fragment ServicesSectionFragment on WPGraphQL_RootQueryToServiceConnection {
  edges {
    node {
      title
      slug
      excerpt
      servicesAcf {
        featuredImage {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 394
                quality: 100
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
}
`

